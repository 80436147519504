import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { currencyFormat } from "./_helpers/helpers";

export default function DashboardMainReferred({ referred, income_referred }) {
    let referred_total = referred ? referred.total : "--.--";
    let income_total =
        income_referred && income_referred.total
            ? currencyFormat(income_referred.total.earnings_unpaid, true)
            : "--.--";
    let income_trips =
        income_referred && income_referred.total ? income_referred.total.trips_unpaid : "--";

    return (
        <Paper>
            <Box p={2}>
                <Divider>Promotores</Divider>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography sx={{ flexGrow: 1, opacity: 0.5 }}>Referidos</Typography>
                    <Typography variant="h6">{referred_total}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography sx={{ flexGrow: 1, opacity: 0.5 }}>Ganancias</Typography>
                    <Typography variant="h6">{income_total}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography sx={{ flexGrow: 1, opacity: 0.5 }}>Viajes</Typography>
                    <Typography variant="h6">{income_trips}</Typography>
                </Stack>
            </Box>
        </Paper>
    );
}
