import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
/**Children */
import Loading from "./_global/Loading";
import Splash from "./_global/Splash";
import DashboardSectionHeader from "./DashboardSectionHeader";
import DashboardCampaignsList from "./DashboardCampaignsList";
/**Helpers */
import { useConditionalHook } from "./_hooks/hooks";
import { getPromoterCampaigns } from "./_helpers/requests";

export default function DashboardCampaigns(props) {
    const [loading, setLoading] = useState(true);
    const [campaigns, setCampaigns] = useState();

    useConditionalHook(async () => {
        await getPromoterCampaigns(setCampaigns);
        setLoading(false);
    }, !campaigns);

    if (loading) {
        return <Loading />;
    }

    if (!campaigns || !campaigns.campaigns || !campaigns.campaigns.length) {
        return (
            <Splash
                icon="campaigns"
                title="Aún no hay campañas publicitarias"
                message="En cuanto se añadan nuevas campañas, podrás verlas aquí junto a sus descripciones e instrucciones de uso."
                action="Regresar"
                onClick={() => {
                    window.location = "/" + window.location.search;
                }}
            />
        );
    }

    return (
        <Container maxWidth="lg">
            <Box mt={1}>
                <DashboardSectionHeader
                    title="Publicidad"
                    subtitle="Estas son las campañas publicitarias aprobadas con el contenido necesario para poder sacarles el máximo provecho."
                />
                <DashboardCampaignsList campaigns={campaigns} />
            </Box>
        </Container>
    );
}
