import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
/**Children */
import DashboardAppBar from "./DashboardAppBar";
import DashboardDrawer from "./DashboardDrawer";
import DashboardMain from "./DashboardMain";
import DashboardDrivers from "./DashboardDrivers";
import DashboardBanking from "./DashboardBanking";
import DashboardCampaigns from "./DashboardCampaigns";

export default function Dashboard(props) {
    const [sidemenu_open, setSideMenuOpen] = React.useState(false);

    return (
        <>
            <DashboardAppBar setSideMenuOpen={setSideMenuOpen} />
            <DashboardDrawer open={sidemenu_open} setSideMenuOpen={setSideMenuOpen} />
            <Routes>
                <Route path="*" element={<DashboardMain />} />
                <Route path="conductores" element={<DashboardDrivers />} />
                <Route path="depositos" element={<DashboardBanking />} />
                <Route path="publicidad" element={<DashboardCampaigns />} />
            </Routes>
        </>
    );
}
