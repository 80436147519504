import React, { useState } from "react";
/**Material UI Dependencies */
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
/**Material UI Icons */
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
/**Helpers */
import { promoterLogin } from "./_helpers/requests";
import { getDeviceID } from "./_helpers/helpers";
import ThemeToggle from "./_global/ThemeToggle";
import { styled } from "@mui/system";
import { browserName } from "react-device-detect";

const Background = styled("div")(({ theme }) => ({
    zIndex: 2,
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.palette.mode === "dark" ? "url(/map_b_d.png)" : "url(/map_b.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    filter: "blur(6px)",
}));

export default function Login(props) {
    const [loading, setLoading] = useState();
    const [input, setInput] = useState({
        device_id: getDeviceID(),
        os: browserName,
    });
    const [errors, setErrors] = useState(false);

    const handleChange = (prop) => (event) => {
        setInput({ ...input, [prop]: event.target.value });
    };

    async function login(event) {
        event.preventDefault();
        if (!input.uid) {
            setErrors({
                uid_error: "Escribe tu correo electrónico o telefono",
            });
            return false;
        }

        if (!input.password) {
            setErrors({
                password_error: "Escribe tu contraseña",
            });
            return false;
        }

        await promoterLogin(setErrors, input);
    }

    return (
        <div>
            <Background style={styles.background} />
            <Container component="main" maxWidth="xs" style={styles.container}>
                <div style={styles.panel}>
                    <Box mt={2} style={styles.box}>
                        <img alt="logo" style={styles.logo} src="/logo.png" />
                    </Box>
                    <Box mt={2} style={styles.box}>
                        <Typography variant="h4" align="center">
                            Siti Reach
                        </Typography>
                        <Typography align="center">
                            La herramienta de alcance de Siti Movilidad
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <form onSubmit={login}>
                            <TextField
                                fullWidth
                                label="Correo electrónico / Telefono"
                                autoFocus
                                margin="normal"
                                variant="outlined"
                                error={Boolean(errors.uid_error)}
                                helperText={errors.uid_error}
                                onChange={handleChange("uid")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Contraseña"
                                type="password"
                                margin="normal"
                                variant="outlined"
                                error={Boolean(errors.password_error)}
                                helperText={errors.password_error}
                                onChange={handleChange("password")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box mt={2}>
                                <Typography color="error">{errors.server_error}</Typography>
                                <Typography color="error">{props.errors.server_error}</Typography>
                            </Box>
                            <Button
                                style={styles.submit}
                                type="submit"
                                onClick={login}
                                disabled={!Boolean(input.uid && input.password && !loading)}
                                variant="contained"
                                color="primary"
                            >
                                {loading ? "Iniciando sesión..." : "Enviar"}
                            </Button>
                        </form>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body2" align="center">
                            <Link
                                href="https://sitimovilidad.com"
                                variant="body2"
                                target="_blank"
                                rel="noopener"
                            >
                                Siti Movilidad
                            </Link>{" "}
                            ®{new Date().getFullYear()}
                        </Typography>
                        <Typography variant="caption" align="center">
                            Todos los derechos reservados
                        </Typography>
                    </Box>
                    <ThemeToggle theme={props.theme} switchTheme={props.switchTheme} />
                </div>
            </Container>
        </div>
    );
}

const styles = {
    container: {
        zIndex: 2,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    panel: {
        textAlign: "center",
    },
    bottom: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    icon: {
        width: "8px",
        height: "8px",
        color: "#F9F9F9",
    },
    logo: {
        height: 80,
        marginBottom: 10,
        borderRadius: 10,
    },
    box: {
        textAlign: "center",
    },
    submit: {
        width: "100%",
        marginTop: 20,
    },
    divider: {
        width: "80%",
        border: "solid 0 #999",
        borderBottomWidth: 1,
        height: 15,
        opacity: 0.5,
        margin: "auto",
    },
};
