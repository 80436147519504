import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import moment from "moment/min/moment-with-locales";

moment.locale("es");

export default function DashboardBankingDeposits({ deposits }) {
    let deposits_list = Object.values(deposits).map((deposit) => {
        let amount = deposit.amount;
        let currency = deposit.currency;
        let color = deposit.type === "driver" ? "success.main" : "primary.main";
        let type_label = deposit.type === "driver" ? "Conductores" : "Promotoria";
        let txn_id = deposit.txn_id;

        let year = deposit.year;
        let week = deposit.week;
        let name = deposit.bank.name;
        let account = deposit.bank.account;
        let date = moment(deposit.created_at).local().format("LL hh:mm A");

        return (
            <Paper sx={{ my: 2, p: 2 }}>
                <Stack direction="row" alignItems="center" mb={1}>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ flexGrow: 1 }}>
                        <Typography variant="h5" color={color}>
                            $
                        </Typography>
                        <Typography variant="h2">{amount}</Typography>
                        <Typography color={color}>{currency}</Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-end">
                        <Typography color={color}>{type_label}</Typography>
                        <Typography>{date}</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" mt={1}>
                    <Stack direction="column" sx={{ flexGrow: 1, opacity: 0.5 }}>
                        <Typography>Folio</Typography>
                        <Typography>Año</Typography>
                        <Typography>Semana</Typography>
                        <Typography>Banco</Typography>
                        <Typography>Cuenta</Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-end">
                        <Typography>{txn_id}</Typography>
                        <Typography>{week}</Typography>
                        <Typography>{year}</Typography>
                        <Typography>{name}</Typography>
                        <Typography>{account}</Typography>
                    </Stack>
                </Stack>
            </Paper>
        );
    });

    return <Box m={1}>{deposits_list}</Box>;
}
