import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import DashboardMainIncome from "./DashboardMainIncome";
import DashboardMainDrivers from "./DashboardMainDrivers";
import DashboardMainReferred from "./DashboardMainReferred";
import DashboardMainCode from "./DashboardMainCode";
import DashboardMainPromoter from "./DashboardMainPromoter";
import Loading from "./_global/Loading";
/**Helpers */
import {
    getPromoter,
    getPromoterUser,
    getPromoterIncome,
    getPromoterDriversIncome,
    getPromoterReferredIncome,
    getPromoterDrivers,
    getPromoterReferred,
} from "./_helpers/requests";
import { useConditionalHook } from "./_hooks/hooks";

export default function DashboardMain(props) {
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [promoter, setPromoter] = useState();
    const [user, setUser] = useState();
    const [drivers, setDrivers] = useState();
    const [referred, setReferred] = useState();
    const [income, setIncome] = useState();
    const [income_drivers, setIncomeDrivers] = useState();
    const [income_referred, setIncomeReferred] = useState();

    useConditionalHook(async () => {
        await getPromoter(setPromoter);
        await getPromoterUser(setUser);
        await getPromoterIncome(setIncome);
        await getPromoterDriversIncome(setIncomeDrivers);
        await getPromoterReferredIncome(setIncomeReferred);
        await getPromoterDrivers(setDrivers);
        await getPromoterReferred(setReferred);
        setInitialized(true);
        setLoading(false);
    }, !initialized);

    if (loading) {
        return <Loading />;
    }

    return (
        <Container maxWidth="lg">
            <Box mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DashboardMainPromoter user={user} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardMainIncome income={income} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardMainDrivers drivers={drivers} income_drivers={income_drivers} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardMainReferred
                            referred={referred}
                            income_referred={income_referred}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DashboardMainCode promoter={promoter} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
