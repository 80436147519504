import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import moment from "moment/min/moment-with-locales";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";

moment.locale("es");

export default function DashboardDriversList({ drivers }) {
    if (!drivers) {
        return null;
    }

    if (!drivers.drivers) {
        return null;
    }

    let drivers_list = Object.values(drivers.drivers).map((driver) => {
        let name = driver.name;
        let rating = driver.rating;
        let last_activity = moment(driver.last_activity).local().format("LL hh:mm A");
        let recent_trips = driver.recent_trips;
        let picture = driver.picture;
        let created_at = moment(driver.created_at).local().format("LL hh:mm A");

        let recent_trips_color = "success.main";
        let recent_trips_label = "Buen desempeño";
        if (recent_trips < 50) {
            recent_trips_color = "primary.main";
            recent_trips_label = "Podria mejorar";
        }
        if (recent_trips < 30) {
            recent_trips_color = "warning.main";
            recent_trips_label = "Mal desempeño";
        }
        if (recent_trips < 10) {
            recent_trips_color = "error.main";
            recent_trips_label = "Posible perdida";
        }

        return (
            <Paper sx={{ my: 2, p: 2 }} key={name}>
                <Stack direction="row" alignItems="center" mb={1}>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ flexGrow: 1 }}>
                        <Avatar src={picture} />
                        <Typography variant="h5">{name}</Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-end">
                        <Rating value={rating} precision={0.5} readOnly />
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" mt={1}>
                    <Stack direction="column" sx={{ flexGrow: 1, opacity: 0.5 }}>
                        <Typography variant="h5">Viajes recientes</Typography>
                        <Typography>Última actividad</Typography>
                        <Typography>Fecha de inscripción</Typography>
                    </Stack>
                    <Stack direction="column" alignItems="flex-end">
                        <Typography variant="h5" color={recent_trips_color}>
                            {recent_trips}
                        </Typography>
                        <Typography>{last_activity}</Typography>
                        <Typography>{created_at}</Typography>
                    </Stack>
                </Stack>
            </Paper>
        );
    });

    return <Box m={1}>{drivers_list}</Box>;
}
