import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
/**Children */
import Loading from "./_global/Loading";
import Splash from "./_global/Splash";
import DashboardSectionHeader from "./DashboardSectionHeader";
import DashboardDriversList from "./DashboardDriversList";
/**Helpers */
import { useConditionalHook } from "./_hooks/hooks";
import { getPromoterDrivers } from "./_helpers/requests";

export default function DashboardDrivers(props) {
    const [loading, setLoading] = useState(true);
    const [drivers, setDrivers] = useState();

    useConditionalHook(async () => {
        await getPromoterDrivers(setDrivers);
        setLoading(false);
    }, !drivers);

    if (loading) {
        return <Loading />;
    }

    if (drivers && drivers.drivers && (!drivers.drivers.length || !drivers.total)) {
        return (
            <Splash
                icon="drivers"
                title="Aún no hay conductores"
                message="En cuanto se registren conductores con tu referencia, podrás ver su actividad en este panel."
                action="Regresar"
                onClick={() => {
                    window.location = "/" + window.location.search;
                }}
            />
        );
    }

    return (
        <Container maxWidth="lg">
            <Box mt={1}>
                <DashboardSectionHeader title="Conductores" />
                <DashboardDriversList drivers={drivers} />
            </Box>
        </Container>
    );
}
