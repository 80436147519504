import React from "react";
/**Material UI Dependencies */
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/material/styles";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";

export default function Loading(props) {
    return (
        <Container component="main" maxWidth="xs" style={styles.container}>
            <Box style={styles.loading}>
                <LinearProgress />
            </Box>
        </Container>
    );
}

const styles = {
    container: {
        top: 0,
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        bottom: 0,
        left: 0,
        right: 0,
    },
    loading: {
        width: "25%",
    },
};
