import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import themeProvider from "./_config/themeProvider";
import queryString from "qs";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Boot from "./Boot";

function getThemeFromQuery() {
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    if (query.theme) {
        switch (query.theme) {
            case "dark":
                return "dark";
            default:
                return "default";
        }
    }

    return "default";
}

function createTheme(theme = "default") {
    switch (theme) {
        case "dark":
            return themeProvider.theme("dark");
        default:
            return themeProvider.theme();
    }
}

export default function App() {
    const [theme, setTheme] = useState(getThemeFromQuery());

    function switchTheme() {
        let change = theme === "dark" ? "default" : "dark";
        setTheme(change);

        const url = new URL(window.location);
        url.searchParams.set("theme", change);
        window.history.pushState({}, "", url);
    }

    return (
        <ThemeProvider theme={createTheme(theme)}>
            <CssBaseline />
            <Boot theme={theme} switchTheme={switchTheme} />
        </ThemeProvider>
    );
}
