export async function getData(url = "", token = null) {
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
    };
    if (token) {
        headers = {
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        };
    }
    return await fetch(url, {
        headers: headers,
    });
}

export async function postData(url = "", token = null, data = {}) {
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
    };
    if (token) {
        headers = {
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        };
    }
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: new URLSearchParams(data),
    });
}
