import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
/**Children */
import Loading from "./_global/Loading";
import Splash from "./_global/Splash";
import DashboardSectionHeader from "./DashboardSectionHeader";
import DashboardBankingDeposits from "./DashboardBankingDeposits";
/**Helpers */
import { useConditionalHook } from "./_hooks/hooks";
import { getPromoterDeposits } from "./_helpers/requests";

export default function DashboardBanking(props) {
    const [loading, setLoading] = useState(true);
    const [deposits, setDeposits] = useState();

    useConditionalHook(async () => {
        await getPromoterDeposits(setDeposits);
        setLoading(false);
    }, !deposits);

    if (loading) {
        return <Loading />;
    }

    if (deposits && !deposits.length) {
        return (
            <Splash
                icon="deposits"
                title="Aún no hay depósitos"
                message="En cuanto se registren viajes de tus referencias, los depósitos del programa se verán reflejados aquí."
                action="Regresar"
                onClick={() => {
                    window.location = "/" + window.location.search;
                }}
            />
        );
    }

    return (
        <Container maxWidth="lg">
            <Box mt={1}>
                <DashboardSectionHeader title="Depósitos" />
                <DashboardBankingDeposits deposits={deposits} />
            </Box>
        </Container>
    );
}
