import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/**Children */
import Loading from "./_global/Loading";
import Login from "./Login";
import Dashboard from "./Dashboard";
/**Helpers */
import { promoterSession } from "./_helpers/requests";
import { useConditionalHook } from "./_hooks/hooks";

export default function Boot(props) {
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState();
    const [errors, setErrors] = useState(false);

    useConditionalHook(async () => {
        await promoterSession(setSession, setErrors);
        setLoading(false);
    }, !session);

    if (loading) {
        return <Loading />;
    }

    if (!session) {
        return <Login theme={props.theme} switchTheme={props.switchTheme} errors={errors} />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Dashboard />} />
            </Routes>
        </BrowserRouter>
    );
}
