import * as React from "react";
import { LinkWithQuery } from "./_global/Link";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import StadiumIcon from '@mui/icons-material/Stadium';
import { logOff } from "./_helpers/helpers";

export default function DashboardDrawerMenu(props) {
    return (
        <Box>
            <List sx={{ minWidth: 200 }}>
                <LinkWithQuery to="/" onClick={() => props.setSideMenuOpen(false)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItemButton>
                </LinkWithQuery>
                <LinkWithQuery to="conductores" onClick={() => props.setSideMenuOpen(false)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <DriveEtaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Conductores" />
                    </ListItemButton>
                </LinkWithQuery>
                <LinkWithQuery to="depositos" onClick={() => props.setSideMenuOpen(false)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Depósitos" />
                    </ListItemButton>
                </LinkWithQuery>
                <LinkWithQuery to="publicidad" onClick={() => props.setSideMenuOpen(false)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <StadiumIcon />
                        </ListItemIcon>
                        <ListItemText primary="Publicidad" />
                    </ListItemButton>
                </LinkWithQuery>
            </List>
            <Divider />
            <List>
                <ListItemButton onClick={logOff}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText secondary="Cerrar sesión" />
                </ListItemButton>
            </List>
        </Box>
    );
}

const styles = {
    control_center_panel: {},
    nested: {
        paddingLeft: 15,
    },
    paper: {
        margin: 8,
        borderRadius: 10,
    },
    environment: {
        textAlign: "center",
    },
};
