import { useEffect, useRef } from "react";

/**
 * Runs the callback continously on the given interval delay.
 * If run first is false, will execute the callback until
 * the interval delay has ocurred.
 */
export function useIntervalHook(callback, delay, run_first = true) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            //Run once first
            if (run_first) {
                tick();
            }
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay, run_first]);
}

/**
 * Runs the callback after given delay.
 */
export function useDelayedHook(callback, conditional, delay = 500) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        let id = setTimeout(tick, delay);
        return () => clearTimeout(id);
    }, [conditional, delay]);
}

/**
 * Will run the callback on "ready" state change.
 */
export function useConditionalHook(callback, conditional) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (conditional) {
            savedCallback.current();
        }
    }, [conditional]);
}
