import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function DashboardMainCode({ promoter }) {
    let code = promoter && promoter.promoter ? promoter.promoter.code : "--";

    return (
        <Paper bgcolor="primary.dark">
            <Box p={2}>
                <Typography variant="h6">Codigo de referencia</Typography>
                <Box p={0.5} my={1} bgcolor="primary.dark" borderRadius={1}>
                    <Typography
                        variant="h4"
                        color="#FFF"
                        sx={{
                            fontFamily: "monospace",
                        }}
                    >
                        {code}
                    </Typography>
                </Box>
                <Typography>Con este código podrás referir conductores y promotores.</Typography>
            </Box>
        </Paper>
    );
}
