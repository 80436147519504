import React from "react";
/**Material UI Dependencies */
import IconButton from "@mui/material/IconButton";
/**Material UI Icons */
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
import Brightness6OutlinedIcon from "@mui/icons-material/Brightness6Outlined";

export default function ThemeToggle(props) {
    return (
        <IconButton color="primary" onClick={() => props.switchTheme()}>
            {props.theme === "dark" ? <Brightness6OutlinedIcon /> : <Brightness2OutlinedIcon />}
        </IconButton>
    );
}
