import { get as getCookie, set as setCookie, remove as removeCookie } from "es-cookie";
import { v4 as generateUUID } from "uuid";

export const logOff = () => {
    removeCookie("uid");
    window.location.reload(false);
};

export const getDeviceID = () => {
    let id = getCookie("id");

    if (id) {
        return id;
    }

    return generateDeviceID();
};

export const generateDeviceID = () => {
    let id = generateUUID();
    setCookie("id", id, { expires: 365 });

    return id;
};

export const currencyFormat = (amount, symbol = false) => {
    let sign = symbol ? "$" : "";
    return (
        sign +
        Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
};
