import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function DashboardMainPromoter({ user }) {
    let name = user && user.user ? user.user.name : "--";
    let email = user && user.user ? user.user.email : "--";

    return (
        <Box p={2}>
            <Typography variant="h6">{name}</Typography>
            <Typography>{email}</Typography>
        </Box>
    );
}
