/**Material UI dependencies */
import { createTheme } from "@mui/material/styles";

export default class themeProvider {
    static theme(type = "light") {
        switch (type) {
            case "dark":
                return createTheme({
                    palette: {
                        primary: {
                            main: "#1190C0",
                        },
                        secondary: {
                            main: "#666666",
                        },
                        mode: "dark",
                    },
                    shape: {
                        borderRadius: 10,
                    },
                    typography: {
                        h1: {
                            fontSize: "2.5rem",
                        },
                        h2: {
                            fontSize: "2.25rem",
                        },
                        h3: {
                            fontSize: "2rem",
                        },
                        h4: {
                            fontSize: "1.75rem",
                        },
                        h5: {
                            fontSize: "1.5rem",
                        },
                        h6: {
                            fontSize: "1.25rem",
                        },
                    },
                });
            default:
                return createTheme({
                    palette: {
                        primary: {
                            main: "#1190C0",
                        },
                        secondary: {
                            main: "#666666",
                        },
                    },
                    shape: {
                        borderRadius: 10,
                    },
                    typography: {
                        h1: {
                            fontSize: "2.5rem",
                        },
                        h2: {
                            fontSize: "2.25rem",
                        },
                        h3: {
                            fontSize: "2rem",
                        },
                        h4: {
                            fontSize: "1.75rem",
                        },
                        h5: {
                            fontSize: "1.5rem",
                        },
                        h6: {
                            fontSize: "1.25rem",
                        },
                    },
                });
        }
    }
}
