import queryString from "qs";
import { get as getCookie, set as setCookie } from "es-cookie";
/**Helpers */
import { getData, postData } from "./interfaces";

const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true });

let url = process.env.REACT_APP_API;
let uid = getCookie("uid");

function loginRedirect(uid) {
    setCookie("uid", uid, { expires: 365 });

    window.location.reload(false);
}

export async function promoterLogin(setErrors, query) {
    let endpoint = "promoter/login";
    await postData(url + endpoint, null, query)
        .then((response) => response.json())
        .then((response) => {
            if (response.error) {
                switch (response.error.code) {
                    case "L-001":
                        setErrors({
                            loading: false,
                            server_error: false,
                            email_error: response.error.message,
                            password_error: false,
                        });
                        break;
                    case "L-002":
                        setErrors({
                            loading: false,
                            server_error: "El correo o la contraseña son incorrectos",
                            email_error: false,
                            password_error: false,
                        });
                        break;
                    case "L-003":
                        setErrors({
                            loading: false,
                            server_error: false,
                            email_error: false,
                            password_error: response.error.message,
                        });
                        break;
                    default:
                        setErrors({
                            server_error: "Hubo un error al iniciar sesión, inténtalo nuevamente.",
                            email_error: false,
                            password_error: false,
                        });
                        break;
                }
            }
            if (response.success) {
                loginRedirect(response.success.token);
            }
        });
}

export async function promoterSession(setData, setErrors) {
    await getData(url + "promoter/session", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.error) {
                switch (response.error.code) {
                    case "SS-JUDI98E":
                        setErrors({
                            server_error: response.error.message,
                        });
                        break;
                }
            }
            if (response.success) {
                setData(true);
            }
        });
}

export async function getPromoter(setData) {
    await getData(url + "promoter", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterUser(setData) {
    await getData(url + "promoter/user", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterDrivers(setData) {
    await getData(url + "promoter/drivers", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterCampaigns(setData) {
    await getData(url + "promoter/campaigns", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterReferred(setData) {
    await getData(url + "promoter/referred", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterIncome(setData) {
    await getData(url + "promoter/income?past=47", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterDriversIncome(setData) {
    await getData(url + "promoter/income/drivers?past=47", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterReferredIncome(setData) {
    await getData(url + "promoter/income/referral?past=47", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterBanks(setData) {
    await getData(url + "promoter/banking/banks", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterDeposits(setData) {
    await getData(url + "promoter/banking/deposits", uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}

export async function getPromoterBank(setData, account) {
    await getData(url + "promoter/banking?account=" + account, uid)
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setData(response.success);
            }
        });
}
