import React from "react";
/**Material UI Dependencies */
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function Splash(props) {
    let icon = <AccountBalanceWalletIcon sx={{ fontSize: 60 }} />;
    let icon_size = 80;
    let color = props.color ? props.color : "primary";
    let title = props.title ? props.title : null;
    let message = props.message ? props.message : null;
    let caption = props.caption ? props.caption : null;
    let action = props.action ? props.action : null;

    switch (props.icon) {
        case "deposits":
            icon = <AccountBalanceWalletIcon sx={{ fontSize: icon_size }} color={color} />;
            break;
        case "drivers":
            icon = <DriveEtaIcon sx={{ fontSize: icon_size }} color={color} />;
            break;
        default:
            icon = <ErrorIcon sx={{ fontSize: icon_size }} color={color} />;
            break;
    }

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                top: 0,
                display: "flex",
                position: "fixed",
                alignItems: "center",
                justifyContent: "center",
                bottom: 0,
                left: 0,
                right: 0,
            }}
        >
            <Stack
                direction="column"
                sx={{ textAlign: "center", alignItems: "center" }}
                spacing={1}
            >
                {icon}
                <Typography variant="h6">{title}</Typography>
                <Typography>{message}</Typography>
                <Typography variant="caption">{caption}</Typography>
                {props.onClick && (
                    <Button onClick={props.onClick} variant="contained">
                        {action}
                    </Button>
                )}
            </Stack>
        </Container>
    );
}
