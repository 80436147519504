import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import moment from "moment/min/moment-with-locales";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grid from "@mui/material/Grid";

moment.locale("es");

export default function DashboardCampaignsList({ campaigns }) {
    if (!campaigns) {
        return null;
    }

    if (!campaigns.campaigns) {
        return null;
    }

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    let campaigns_list = Object.values(campaigns.campaigns).map((campaign) => {
        let title = campaign.title;
        let description = campaign.description;
        let conditions = campaign.conditions;
        let preview_url = campaign.preview_url;
        let content_url = campaign.content_url;
        let type = campaign.type;
        let uuid = campaign.uuid;
        let updated_at = moment(campaign.updated_at).local().format("LL");
        let valid_from = moment(campaign.valid_from).local().format("LL");
        let expiration = moment(campaign.expiration).local().format("LL");

        return (
            <Grid item xs={4} sm={4} md={4} key={uuid}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} f>
                                R
                            </Avatar>
                        }
                        title={title}
                        subheader={valid_from}
                        action={
                            <IconButton
                                onClick={() => {
                                    openInNewTab(content_url);
                                }}
                            >
                                <ArrowCircleDownIcon />
                            </IconButton>
                        }
                    />
                    <CardMedia component="img" height="194" image={preview_url} />
                    <CardContent>
                        <Typography>{description}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {conditions}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    });

    return (
        <Box m={1}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {campaigns_list}
            </Grid>
        </Box>
    );
}
