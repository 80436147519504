import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { currencyFormat } from "./_helpers/helpers";

export default function DashboardMainIncome({ income }) {
    let total = income ? currencyFormat(income.earnings_unpaid, true) : "--.--";

    return (
        <Box p={2}>
            <Divider>Ganancias pagables</Divider>
            <Typography variant="h1" textAlign="center" sx={{ marginTop: 1 }}>
                {total}
            </Typography>
        </Box>
    );
}
