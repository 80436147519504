import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function DashboardSectionHeader(props) {
    let title = props.title ? <Typography variant="h3">{props.title}</Typography> : "--";
    let subtitle = props.subtitle ? <Typography>{props.subtitle}</Typography> : null;

    return (
        <Box p={1}>
            {title}
            {subtitle}
        </Box>
    );
}
