import * as React from "react";
import Drawer from "@mui/material/Drawer";
import PerfectScrollbar from "react-perfect-scrollbar";
import DashboardDrawerMenu from "./DashboardDrawerMenu";

const anchor = "left";

export default function DashboardDrawer(props) {
    return (
        <Drawer
            style={styles.drawer}
            anchor={anchor}
            open={props.open}
            onClose={() => props.setSideMenuOpen(false)}
        >
            <PerfectScrollbar>
                <DashboardDrawerMenu
                    theme={props.theme}
                    switchTheme={props.switchTheme}
                    setSideMenuOpen={props.setSideMenuOpen}
                />
            </PerfectScrollbar>
        </Drawer>
    );
}

const styles = {
    drawer: {
        flexShrink: 0,
    },
    paper: {
        backgroundColor: "transparent",
        backdropFilter: "blur(15px)",
    },
    desktop_drawer: {
        top: 64,
        height: "calc(100% - 64px)",
    },
};
